<i18n locale="th" lang="yaml" >
page.title : "{name} :: ใบซ่อมใหม่ (รับต่อมาจากศูนย์)"
page.description : "เปิดงานซ่อมสำหรับ Drone โดยรับต่อมาจากศูนย์ {companyName} ด้วยหมายเลขงานซ่อม {serviceNo}"

service_create.confirm.title : "ยืนยันการเปิดงานซ่อม"
service_create.confirm.message : "คุณต้องการยืนยันการเปิดงานซ่อมตามนี้ ?"
service_create.success : "เปิดงานซ่อมเรียบร้อยด้วย เลขอ้างอิง {service}"
service_create.submit.label : "เปิดงานซ่อม"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: drone.serial_no })"
			:description="$t('page.description',{ companyName: fromCompanyName,serviceNo: fromServiceNo })">
			<my-router-link
				slot="description"
				name="service/view"
				:param-value="fromService.id"
				new-window>
				{{fromServiceNo}}
			</my-router-link>
		</my-page-header>

		<div class="mylayout-left-right responsive">
			<div class="mylayout-left small">
				<DroneCard
					class="sticky"
					:drone="droneData.drone"
					:lot="droneData.lot"
					:model="droneData.model"
					:owner="droneData.owner"
					:firmware="droneData.firmware"
					:connect="droneData.connect"/>
			</div>
			<div class="mylayout-right">
				<a-card :bordered="false">
					<ServiceForm
						ref="formRef"
						:loading="loading"
						mode="create_service"
						@submit="handleSubmit">
						<template v-slot:submitSlot="{ formLoading }">
							<a-button
								:disabled="formLoading"
								icon="left"
								size="large"
								:style="{ 'margin-right': '4px' }"
								@click="goBack">
								{{$t('common.back')}}
							</a-button>
							<a-button
								:loading="formLoading"
								type="info"
								html-type="submit"
								class="btn-submit"
								icon="edit"
								size="large">
								{{$t('service_create.submit.label')}}
							</a-button>
						</template>
					</ServiceForm>
				</a-card>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import ServiceForm from "@components/service/ServiceForm.vue"
import DroneCard from "@components/drone/DroneCard.vue"
import get from "lodash/get"

export default {
	components : {
		ServiceForm, DroneCard
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name: this.drone.serial_no})
		}
	} ,
	data() {
		return {
			droneData : {} ,
			fromService : {} ,
			loading : false,
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		fromCompanyName() {
			return get(this.fromService,'company.name')
		} ,
		fromServiceNo() {
			return get(this.fromService,'service_no')
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			const fromServiceId = this.$route.params.id
			this.showPageLoading(true)
			this.clearBreadcrumbParams(['serviceNo','droneSerialNo'])
			axios.get("/api/services/init-from-service/"+fromServiceId).then((response) => {
				this.fromService = response.data.data.from_service
				this.droneData = this.fromService.droneData

				const service = response.data.data.service
				const repair = response.data.data.repair
				this.$refs.formRef.updateReferredServiceForm(this.droneData,service,repair,this.fromService)

				this.addBreadcrumbParams({
					serviceNo: this.fromService.service_no ,
					droneSerialNo : this.droneData.drone.serialNo
				})
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('service_create.confirm.title') ,
				content : this.$t('service_create.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true
					formData.from_service_id = this.fromService.id

					axios.post('/api/services/create',formData).then((response)=>{
						const service = response.data.data.service;

						this.$message.success(this.$t('service_create.success',{"service" : service.service_no}))
						this.$router.push({name:'service/view',params: {id : service.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			})
		} ,
		goBack() {
			return this.$open({name : 'drone/view',params : {serial_no : this.drone.serial_no}})
		}
	}
}
</script>
